import React, { useEffect, createRef, RefObject } from "react";
import { LayoutLP } from "../../components/layouts/layout-lp";
import { Container, Row, Col } from "react-bootstrap";
import sitefinity from "../../images/logo/sitefinity.png";
import sitecore from "../../images/logo/sitecore.png";
import sharepoint from "../../images/logo/sharepoint.png";
import umbraco from "../../images/logo/umbraco.png";
import drupal from "../../images/logo/drupal.png";
import azure from "../../images/logo/azure.png";
import angular from "../../images/logo/angular.png";
import dotnet from "../../images/logo/dotnet.png";
import CarsalesCircle from "../../images/Carsales_circle.png";
import WesfarmersCircle from "../../images/Wesfarmers_circle.png";
import InvestTradeCircle from "../../images/InvestTrade_circle.png";
import MurdochCircle from "../../images/Murdoch_circle.png";
import VisibuyCircle from "../../images/Visibuy_circle.png";
import contactUsImage from "../../images/contactus-img.jpg";
import contactUsMarker from "../../images/contactus-marker.png";
import contactUsPhone from "../../images/contactus-phone.png";
import contactUsEmail from "../../images/contactus-email.png";
import CaseCarousel from "../../components/case-carousel/case-carousel";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../../components/shared/seo";
import { LogoText } from "../../components/shared/logo";
import { PrimaryButton } from "../../components/buttons/primary-button";
import ContactUsForm from "../../components/shared/contact-us-form";

import {
    IoIosTrendingUp,
    IoMdLaptop,
    IoIosCode,
    IoIosCloud,
    IoIosDoneAll,
} from "react-icons/io";

function LinuxDev() {

    const getInTouchSectionRef: RefObject<HTMLDivElement> = createRef();

    const lpImages = useStaticQuery(graphql`
    query {
      homeBanner: file(relativePath: { eq: "home-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      homeImage2: file(relativePath: { eq: "diviv-22-filter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      bgTexture: file(relativePath: { eq: "bg-texture.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      Pindan: file(relativePath: { eq: "card-pindan.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pindanLogo: file(relativePath: { eq: "pindan-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      visibuyLogo: file(relativePath: { eq: "visibuy-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      visibuy: file(relativePath: { eq: "visibuy-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      investTradeLogo: file(relativePath: { eq: "invest-trade-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      investTrade: file(relativePath: { eq: "invest-trade-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energyAction: file(relativePath: { eq: "card-energyaction.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      eaLogo: file(relativePath: { eq: "ea-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wesfarmers: file(relativePath: { eq: "card-wesfarmers.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wesfarmersLogo: file(relativePath: { eq: "wesfarmers-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stJohnLogo: file(relativePath: { eq: "st-john-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energyActionLogo: file(relativePath: { eq: "ea-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      stJohnVas: file(relativePath: { eq: "card-vas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stJohn: file(relativePath: { eq: "card-stjohn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      divivLogo: file(relativePath: { eq: "logo-icon-cyan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wesfarmersSustainability: file(relativePath: { eq: "wesfarmers-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      murdochLogo: file(relativePath: { eq: "murdoch-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      murdochCourse: file(relativePath: { eq: "Murdoch-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      murdoch: file(relativePath: { eq: "card-murdoch.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

    return (
        <>
            <SEO title="Highly Skilled Australian Linux Developers" />
            <LayoutLP>
                <div
                    id="lp-content"
                    className="page-layout"
                    data-menu-class="inverted"
                >
                    <section className="hero-section">
                        <div className="full-height--background">
                            <div className="text-overlay">
                                <Container>
                                    <Row>
                                        <Col sm={12} className="text-center">
                                            <h2>Highly Skilled Australian Linux Developers</h2>
                                            <h5 className="mt-0 mb-5">Providing Innovative Solutions That Simplify Complex Problems</h5>

                                            <PrimaryButton
                                                onClick={() =>
                                                    getInTouchSectionRef.current.scrollIntoView({
                                                        behavior: "smooth",
                                                    })
                                                }
                                            >
                                                GET IN TOUCH
                                              </PrimaryButton>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </section>
                    <section className="introduction-section">
                        <Container>
                            <Row>
                                <Col md={12} lg={9}>
                                    <h6>Diviv Group</h6>
                                    <h2>About Us</h2>
                                    <p>
                                        At Diviv Group, we care deeply about your business outcomes.
                                        That’s why we deliver creative technology solutions to power
                                        your business and overcome the challenges of the modern
                                        workplace.
                                    </p>
                                    <p>
                                        Operating at the intersection of technology and creativity,
                                        we aim to deliver meaningful outcomes that make an impact on
                                        your business.
                                    </p>
                                    <p>
                                        Our team is made up of technical experts who integrate
                                        complex systems, to facilitate simpler and faster business
                                        processes for our clients. We deliver business insights, so
                                        that leadership teams that regularly rely on our work can
                                        make informed decisions on empirical evidence.
                                    </p>
                                </Col>

                                <Col md={12} lg={3} className="social-media">
                                    <div className="diviv-banner__logo">
                                        <LogoText />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="customers-section">
                        <Container>
                            <Row>
                                <Col sm={12} className="d-flex flex-column flex-sm-row flex-wrap align-items-center justify-content-sm-between">
                                    <img src={CarsalesCircle} className="mb-4" alt="Energy Action" />
                                    <img src={WesfarmersCircle} className="mb-4" alt="Wesfamers" />
                                    <img src={InvestTradeCircle} className="mb-4" alt="Invest and Trade" />
                                    <img src={MurdochCircle} className="mb-4" alt="Pindan" />
                                    <img src={VisibuyCircle} className="mb-4" alt="Visibuy" />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="services-section">
                        <Container>
                            <Row>
                                {/* <Col sm={12}>
                                    <div className="diviv-banner mb-5">
                                        <div className="diviv-banner__logo">
                                            <LogoText />
                                        </div>
                                        <p className="diviv-banner__text">
                                            At Diviv, we do things differently and genuinely care
                                            about our people and our clients. We also believe that
                                            transparency is a vital ingredient in building
                                            long-standing mutually beneficial relationships.
                                        </p>
                                    </div>
                                </Col> */}
                                <Col sm={12} lg={6}>
                                    <div className="about-us-section__icon">
                                        <IoIosCloud
                                            size={50}
                                            className="list--services__item__icon"
                                        />
                                    </div>
                                    <h5 className="subtitle">Business System Integration</h5>
                                    <p>
                                        Our business system integration solutions help you transform
                                        processes and technology investments into innovative
                                        solutions.
                  </p>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="about-us-section__icon">
                                        <IoIosCode
                                            size={50}
                                            className="list--services__item__icon"
                                        />
                                    </div>
                                    <h5 className="subtitle">Application Development</h5>
                                    <p>
                                        We help you build solutions that give you a competitive edge
                                        in today’s digitally driven world.
                  </p>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="about-us-section__icon">
                                        <IoIosDoneAll
                                            size={50}
                                            className="list--services__item__icon"
                                        />
                                    </div>
                                    <h5 className="subtitle">
                                        Technical Expertise &amp; Consultancy
                  </h5>
                                    <p>
                                        Our skilled team of tech professionals can provide the
                                        strategic advice your business needs across the full
                                        spectrum of digital enterprise solutions.
                  </p>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="about-us-section__icon">
                                        <IoIosTrendingUp
                                            size={50}
                                            className="list--services__item__icon"
                                        />
                                    </div>
                                    <h5 className="subtitle">Digital Business Strategy</h5>
                                    <p>
                                        Combining the expertise of leading developers, business
                                        architects, and digital marketing specialists, we deliver a
                                        digital business strategy that works.
                  </p>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="about-us-section__icon">
                                        <IoMdLaptop
                                            size={50}
                                            className="list--services__item__icon"
                                        />
                                    </div>
                                    <h5 className="subtitle">
                                        Website Development &amp; Maintenance
                  </h5>
                                    <p>
                                        Taking a brand-centric approach, we build websites around
                                        what your customers need and what your brand wants to say.
                  </p>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <div className="small-banner">
                                        <h5 className="small-banner__text">
                                            We deliver perpetual excellence across our wide range of
                                            products and services
                    </h5>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="cms-logos-section">
                        <Container>
                            <Row>
                                <div className="company-logo mb-5">
                                    <a href="https://www.sitefinity.com/" target="_blank">
                                        <img src={sitefinity} alt="sitefinity" />
                                    </a>
                                </div>
                                <div className="company-logo mb-5">
                                    <a href="https://www.sitecore.com/" target="_blank">
                                        <img src={sitecore} alt="sitecore" />
                                    </a>
                                </div>
                                <div className="company-logo mb-5">
                                    <div>
                                        <a
                                            href="https://products.office.com/en-AU/SharePoint/"
                                            target="_blank"
                                        >
                                            <img src={sharepoint} alt="sharepoint" />
                                        </a>
                                    </div>
                                </div>
                                <div className="company-logo mb-5">
                                    <div>
                                        <a href="https://umbraco.com/" target="_blank">
                                            <img src={umbraco} alt="umbraco" />
                                        </a>
                                    </div>
                                </div>
                                <div className="company-logo mb-5">
                                    <div>
                                        <a href="https://www.drupal.org/" target="_blank">
                                            <img src={drupal} alt="drupal" />
                                        </a>
                                    </div>
                                </div>
                                <div className="company-logo mb-5">
                                    <div>
                                        <a href="https://azure.microsoft.com/" target="_blank">
                                            <img src={azure} alt="azure" />
                                        </a>
                                    </div>
                                </div>
                                <div className="company-logo mb-5">
                                    <div>
                                        <a href="https://angular.io/" target="_blank">
                                            <img src={angular} alt="angular" />
                                        </a>
                                    </div>
                                </div>
                                <div className="company-logo mb-5">
                                    <div>
                                        <a href="https://dotnet.microsoft.com/" target="_blank">
                                            <img src={dotnet} alt="dotnet" />
                                        </a>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </section>
                    <section className="case-study-section">
                        <Container>
                            <Row>
                                <Col lg={6} sm={12} className="inside-wrapper">
                                    <div className="information">
                                        <h6>Case study</h6>
                                        <h3>Don't make me think!</h3>
                                        <p>
                                            We provide innovative solutions that simplify complex
                                            problems.
                  </p>
                                        <a className="btn btn-primary" href="/our-work">
                                            View all case studies
                  </a>
                                    </div>
                                </Col>

                                <Col lg={6} sm={12}>
                                    <div className="carousel-container">
                                        <CaseCarousel
                                            caseList={[
                                                {
                                                    title: "Sitefinity Custom Development",
                                                    clientName: "Wesfarmers Sustainability",
                                                    backgroundImg:
                                                        lpImages.wesfarmersSustainability.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.wesfarmersLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/wesfarmers-sustainability",
                                                },
                                                {
                                                    title: "Sitefinity Custom Development",
                                                    clientName: "Murdoch Course Page",
                                                    backgroundImg:
                                                        lpImages.murdochCourse.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.murdochLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/murdoch-course",
                                                },
                                                {
                                                    title: "Sitefinity CMS Build",
                                                    clientName: "Invest and Trade",
                                                    backgroundImg:
                                                        lpImages.investTrade.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.investTradeLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/investtrade",
                                                },
                                                {
                                                    title: "Visibuy Website",
                                                    clientName: "Pharmacy777",
                                                    backgroundImg:
                                                        lpImages.visibuy.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.visibuyLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/visibuy",
                                                },
                                                {
                                                    title: "Kentico to Wordpress Migration",
                                                    clientName: "Pindan",
                                                    backgroundImg:
                                                        lpImages.Pindan.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.pindanLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/pindan",
                                                },
                                                {
                                                    title: "Client Portal Rebuild",
                                                    clientName: "Energy Action",
                                                    backgroundImg:
                                                        lpImages.energyAction.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.energyActionLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/energy-action",
                                                },
                                                {
                                                    title: "Support & Development",
                                                    clientName: "Wesfarmers",
                                                    backgroundImg:
                                                        lpImages.wesfarmers.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.wesfarmersLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/wesfarmers",
                                                },
                                                {
                                                    title: "Volunteer Accounting System (VAS)",
                                                    clientName: "St John Ambulance",
                                                    backgroundImg:
                                                        lpImages.stJohnVas.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.stJohnLogo.childImageSharp.fluid.src,
                                                    casePageUrl:
                                                        "/our-work/stjohn-ambulance/volunteer-accounting-system",
                                                },
                                                {
                                                    title: "BEATS Internal News Site",
                                                    clientName: "St John Ambulance",
                                                    backgroundImg:
                                                        lpImages.stJohn.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.stJohnLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/stjohn-ambulance/beats",
                                                },
                                                {
                                                    title: "Support, Development, and Integration",
                                                    clientName: "Murdoch university",
                                                    backgroundImg:
                                                        lpImages.murdoch.childImageSharp.fluid.src,
                                                    clientLogoUrl:
                                                        lpImages.murdochLogo.childImageSharp.fluid.src,
                                                    casePageUrl: "/our-work/murdoch-university",
                                                },
                                            ]}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="contactus-section" ref={getInTouchSectionRef}>
                        <Container>
                            <Row>
                                <Col sm={12} lg={4} className="pr-lg-0">
                                    <div className="contactus__banner">
                                        <img src={contactUsImage} alt="" className="contactus__banner-image" />
                                        <div className="contactus__banner-text p-4">
                                            <h3>Get in Touch</h3>
                                            <div className="d-flex flex-column">
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={contactUsMarker} alt="" className="marker-icon" />
                                                    <span style={{ display: "block", fontSize: 14 }}>165 / 15 Aberdeen St, (Corner of Pier St and Aberdeen St), Perth WA 6000</span>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={contactUsPhone} alt="" className="phone-icon" />
                                                    <a href="tel:1300875978" style={{ textDecoration:"none", color:"#D3D3D3" }}><span style={{ display: "block", fontSize: 14 }}>1300 875 978</span></a>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={contactUsEmail} alt="" className="email-icon" />
                                                    <a href="mailto:hello@diviv.com.au" style={{ textDecoration:"none", color:"#D3D3D3" }}><span style={{ display: "block", fontSize: 14 }}>hello@diviv.com.au</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} lg={8} className="pl-lg-0">
                                    <div className="contact-form-wrapper p-4">
                                        <ContactUsForm />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </LayoutLP>
        </>
    );
}

export default LinuxDev;